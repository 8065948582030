import React from 'react';
import { Router } from '@reach/router';
import { CheckCardBalance } from '../components/routes';
// import NotFound from './404';

const CheckBalancePage = () => {
  return (
    <Router basepath="check-balance">
      <CheckCardBalance path="/:id/:cardNumber" />
      <CheckCardBalance path="/:id" />
      <CheckCardBalance path="/" />
      {/* <NotFound default /> */}
    </Router>
  );
};

export default CheckBalancePage;
